import React from 'react'
import NextHead from 'next/head'

export const AppShellHead = ({ title }: { title: string }) => {
    return (
        <NextHead>
            <title>{title}</title>
        </NextHead>
    )
}
