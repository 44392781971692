import { createContext, useContext } from 'react'
import { UserType } from '@prisma/client'
import { useParams } from 'containers/signin/hooks/useParams'

export const TargetAudienceContext = createContext<
    | {
          targetAudience: Extract<UserType, 'COMPANY_OWNER'>
      }
    | {
          targetAudience: Extract<UserType, 'VENDOR'>
          verificationRequestId: string
      }
>({
    targetAudience: UserType.COMPANY_OWNER,
})

export const useTargetAudience = () => {
    const { type: userType } = useParams()

    const context = useContext(TargetAudienceContext)
    return userType ?? context.targetAudience
}

export const useTargetAudienceContext = () => {
    const { type: userType } = useParams()
    const context = useContext(TargetAudienceContext)
    if (userType) {
        return {
            targetAudience: userType,
        }
    } else {
        return context
    }
}
