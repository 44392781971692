import { Text, Flex } from '@chakra-ui/react'
import { useTargetAudience } from 'containers/signin/context/TargetAudienceContext'

const TARGET_AUDIENCE_TEXT = {
    COMPANY_OWNER: `Access equity-free invoice financing to manage cash flow, pay suppliers and scale faster`,
    VENDOR: `Register to confirm your details: get a verified status at Kikin and enjoy faster payments`,
    INVESTOR:
        'Get an overview of your investment performance: transaction history, investment cycle details, and the interest earned',
    COMPANY_MEMBER: `Access equity-free invoice financing to manage cash flow, pay suppliers and scale faster`,
}

export const Values = () => {
    const targetAudience = useTargetAudience()
    const text = TARGET_AUDIENCE_TEXT[targetAudience]

    return (
        <Flex
            textAlign="center"
            flexDirection={'column'}
            alignItems={'center'}
            mb={{ base: '20px', md: '40px' }}
        >
            <Text
                color="beige.background"
                variant="paragraph-m"
                maxWidth="460px"
                textAlign="center"
                display={{ base: 'none', lg: 'block' }}
                zIndex="docked"
            >
                {text}
            </Text>

            <Text
                color="beige.background"
                variant="caption-m"
                maxWidth="400px"
                textAlign="center"
                display={{ base: 'block', lg: 'none' }}
                zIndex="docked"
            >
                {text}
            </Text>
        </Flex>
    )
}
