import { Box } from '@chakra-ui/react'
import Image from 'next/image'
import BorrowWiselyBadge from 'public/img/badges/svg/borrow_wisely.svg?url'
import ChangeTheWorldBadge from 'public/img/badges/svg/change_the_world.svg?url'
import FundTheFutureBadge from 'public/img/badges/svg/fund_the_future.svg?url'
import GrowthForGoodBadge from 'public/img/badges/svg/growth_for_good.svg?url'
import PutPlanetFirstBadge from 'public/img/badges/svg/put_planet_first.svg?url'
import WorkTogetherBadge from 'public/img/badges/svg/work_together.svg?url'

export const Badges = () => {
    return (
        <Box>
            {/* top badges */}
            <Box
                position="fixed"
                top="-70px"
                right={{ base: '-50px', lg: '200px' }}
                transform="rotate(8.42deg)"
                width={{ base: 140, lg: 240 }}
                height={{ base: 140, lg: 240 }}
            >
                <Image
                    src={BorrowWiselyBadge}
                    alt="Borrow Wisely"
                    width={240}
                    height={240}
                />
            </Box>

            <Box
                position="fixed"
                display={{ base: 'none', xs: 'block' }}
                top="39px"
                right={{ base: '30px', md: '10px' }}
                transform="matrix(0.91, 0.41, -0.43, 0.9, 0, 0)"
                width={{ base: 85, lg: 185 }}
                height={{ base: 80, lg: 180 }}
            >
                <Image
                    src={FundTheFutureBadge}
                    alt="Fund the future"
                    width={185}
                    height={180}
                />
            </Box>

            <Box
                position="fixed"
                top="60px"
                left={{ base: '-80px', lg: '40px' }}
                transform="matrix(0.97, 0.25, -0.26, 0.97, 0, 0)"
                width={{ base: 110, sm: 140, lg: 254 }}
                height={{ base: 110, sm: 140, lg: 254 }}
            >
                <Image
                    src={PutPlanetFirstBadge}
                    alt="Put Planet First"
                    width={254}
                    height={254}
                />
            </Box>

            <Box
                position="fixed"
                top={{ base: '140px', lg: '230px' }}
                left={{ base: '-60px', sm: '-100px', lg: '68px' }}
                transform="matrix(0.96, -0.29, 0.3, 0.95, 0, 0)"
                width={{ base: 78, sm: 128, lg: 168 }}
                height={{ sm: 77, lg: 117 }}
            >
                <Image
                    src={GrowthForGoodBadge}
                    alt="Growth For Good"
                    width={168}
                    height={117}
                />
            </Box>

            {/* bottom badges */}
            <Box
                position="fixed"
                bottom="-110px"
                left={{ md: '-35px', lg: '110px' }}
                display={{ base: 'none', lg: 'block' }}
                transform="rotate(10.06deg)"
                width={{ base: 257 }}
                height={{ base: 257 }}
            >
                <Image
                    src={ChangeTheWorldBadge}
                    alt="Change The World"
                    width={257}
                    height={257}
                />
            </Box>

            <Box
                position="fixed"
                bottom={{ base: '80px', lg: '30px' }}
                right={{ base: '-60px', lg: '-55px' }}
                transform="matrix(0.78, -0.62, 0.64, 0.77, 0, 0);"
                width={{ base: 100, sm: 140, lg: 257 }}
                height={{ base: 100, sm: 140, lg: 257 }}
            >
                <Image
                    src={WorkTogetherBadge}
                    alt="Work Together"
                    width={257}
                    height={257}
                />
            </Box>
        </Box>
    )
}
