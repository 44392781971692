import React, { useEffect } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Badges } from 'containers/signin/components/Badges'
import { Footer } from 'containers/signin/components/Footer'
import { SignIn } from 'containers/signin/components/SignIn'
import { useParams } from 'containers/signin/hooks/useParams'
import { useShowErrorMessage } from 'containers/signin/hooks/useShowErrorMessage'
import { AppShellHead } from 'components/AppShellHead'
import { KikinLogoFull, KikinLogoIcon } from 'components/KikinLogo'

export const SignInPage = () => {
    const showErrorMessage = useShowErrorMessage()
    const params = useParams()

    useEffect(() => {
        if (params.error) {
            showErrorMessage(params.error)
        }
    }, [params.error])

    return (
        <Flex
            direction="column"
            alignItems="center"
            justifyContent={'space-between'}
            h="100vh"
            overflowY="auto"
            bgColor="green.default"
        >
            <AppShellHead title="Kikin - Sign in" />

            <KikinLogoFull
                display={{
                    base: 'none',
                    xs: 'block',
                }}
                mt={{ base: '10px', xs: '40px' }}
                zIndex="docked"
            />

            <Box
                mt="15px"
                w="30px"
                h="30px"
                display={{
                    base: 'block',
                    xs: 'none',
                }}
                zIndex="docked"
            >
                <KikinLogoIcon />
            </Box>
            <SignIn />
            <Footer />
            <Badges />
        </Flex>
    )
}
