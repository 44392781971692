import { chakra } from '@chakra-ui/react'
import Image from 'next/image'
import KikinLogo from 'public/img/kikin_logo.svg'
import LogoIcon from 'public/img/logo.svg?url'
import LogoIconGreen from 'public/img/logo_dark_green.svg?url'
import LogoIconNeon from 'public/img/logo_kikin_neon.svg?url'

export const KikinLogoIcon = () => {
    return <Image src={LogoIcon} height={45} width={36} alt="Kikin logo" />
}

export const KikinLogoIconGreen = ({
    width = 36,
    height = 29,
}: {
    width?: number
    height?: number
}) => {
    return <Image src={LogoIconGreen} height={height} width={width} alt="Kikin logo" />
}

export const KikinLogoFull = chakra(KikinLogo)

export const KikinLogoIconNeonFull = ({ height = 30 }: { height?: number }) => {
    return (
        <Image
            src={LogoIconNeon}
            height={0}
            width={0}
            alt="Kikin logo"
            style={{
                width: 'auto',
                height: height + 'px',
            }}
        />
    )
}
