import { useRouter } from 'next/router'
import { UserType } from '@prisma/client'

type Params = {
    error?: string
    inviteId?: string
    type?: Extract<UserType, 'INVESTOR' | 'COMPANY_MEMBER'>
}

export const useParams = (): Params => {
    const { query } = useRouter()

    const error = query['error'] as string | undefined
    const inviteId = query['inviteId'] as string | undefined
    const type = query['type'] as
        | Extract<UserType, 'INVESTOR' | 'COMPANY_MEMBER'>
        | undefined

    return {
        error,
        inviteId,
        type,
    }
}
