import React from 'react'
import { Text, Link as ChakraLink, Flex } from '@chakra-ui/react'
import { routes } from 'routes'
import { SUPPORT_EMAIL } from 'lib/marketing'

export const Footer = () => {
    return (
        <Flex
            rowGap="10px"
            columnGap={{ base: '30px', md: '32px' }}
            mt="auto"
            p="18px"
            mb="10px"
            flexWrap="wrap"
            justifyContent="center"
            textAlign="left"
            zIndex={'docked'}
        >
            <Text variant="caption-s" color="captions.dark" fontWeight={400}>
                &copy; Kikin
            </Text>

            <ChakraLink href={routes.termsOfService()} isExternal>
                <Text variant="caption-s" color="captions.dark" fontWeight={400}>
                    Terms of service
                </Text>
            </ChakraLink>

            <ChakraLink href={routes.privacyPolicy()} isExternal>
                <Text variant="caption-s" color="captions.dark" fontWeight={400}>
                    Privacy policy
                </Text>
            </ChakraLink>

            <ChakraLink href={`mailto:${SUPPORT_EMAIL}`} isExternal>
                <Text variant="caption-s" color="captions.dark" fontWeight={400}>
                    Contact us
                </Text>
            </ChakraLink>
        </Flex>
    )
}
