import { UseToastOptions, useToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'

const ACCOUNT_NOT_LINKED_ERROR = {
    title: 'Use standard email login',
    description: 'Your account is not connected to google',
}

const VERIFICATION_ERROR = {
    title: 'Verification failed',
    description:
        'Could be an expired magic link. Please try again or contact support for help.',
}

const DEFAULT_ERROR_MESSAGE =
    'Something went wrong. Please try again or contact support for help.'

export const useShowErrorMessage = (): ((error: string) => void) => {
    const toast = useToast()

    return function showErrorMessage(error) {
        let toastOptions: UseToastOptions = {
            status: 'error',
            duration: 4000,
        }

        switch (error) {
            case 'OAuthAccountNotLinked': {
                toastOptions.description = ACCOUNT_NOT_LINKED_ERROR.description
                toastOptions.title = ACCOUNT_NOT_LINKED_ERROR.title
                break
            }
            case 'Verification': {
                toastOptions.description = VERIFICATION_ERROR.description
                toastOptions.title = VERIFICATION_ERROR.title
                break
            }
            default: {
                toastOptions.title = error
                toastOptions.description = DEFAULT_ERROR_MESSAGE
            }
        }

        toast(toastOptions)
        Sentry.captureException({
            description: toastOptions.description,
            error,
        })
    }
}
